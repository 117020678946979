import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Input } from "baseui/input";
import SearchIcon from "baseui/icon/search";
import { ChevronDown, ChevronUp } from "baseui/icon";
import { Accordion, Panel } from "baseui/accordion";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { useState, useEffect } from "react";
import { useTrackedState } from "store/store";
import { Search_Text } from "resources/constants/strings";
import {
  COMMON_RADIUS,
  COMMON_BORDER_COLOR,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_STYLE,
} from "utils/style";
import { COMMON_CARD_STYLE } from "utils/style";
import {
  TableHeader,
  TableTask,
  TableSkillsPoints,
  TableCompletionDate,
} from "./table";
import search from "./search";
import dayjs from "dayjs";
import { getPathAndScore } from "utils/app-utils";

const HEADING_STYLE = (theme) => ({
  fontFamily: "Manrope",
  fontSize: "16px",
  lineHeight: "32px",
  color: theme.RAColors?.black,
  [theme.mediaQuery.small]: {
    fontSize: "14px",
    lineHeight: "23px",
    marginTop: "10px",
    width: "100%",
  },
  [theme.mediaQuery.large]: {
    fontSize: "16px",
    lineHeight: "32px",
    marginTop: "0",
    width: "auto",
  },
});
const SUBHEADING_STYLE = (theme) => ({
  fontFamily: "Manrope-semibold",
  fontSize: "12px",
  lineHeight: "16px",
  color: theme.RAColors?.gray800,
});
const VALUES_STYLE = (theme) => ({
  ...SUBHEADING_STYLE,
  fontFamily: "Manrope-semibold",
  lineHeight: "22px",
  fontSize: "16px",
});

const LESSON_STYLE = (theme) => ({
  ...HEADING_STYLE,
  fontSize: "12px",
  lineHeight: "16px",
});
const ACCORDION_BLOCK_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  padding: "2px",
  marginTop: "15px",
  border: `1px solid ${theme.RAColors?.gray200}`,
});
const LESSON_SECTION = (theme) => ({
  paddingLeft: "2px",
  [theme.mediaQuery.small]: {
    width: "100%",
  },
  [theme.mediaQuery.small]: {
    width: "auto",
  },
});
const SKILL_SECTION = (theme) => ({
  [theme.mediaQuery.small]: {
    width: "100%",
    display: "block",
  },
  [theme.mediaQuery.large]: {
    width: "auto",
    display: "flex",
  },
});
const QUES_SECTION = (theme) => ({
  marginLeft: "25px",
  [theme.mediaQuery.small]: {
    marginLeft: "0px",
  },
  [theme.mediaQuery.large]: {
    marginLeft: "25px",
  },
});
const FILTER_STYLE = (theme) => ({
  marginBottom: "24px",
});
const FILTER_SEARCH = (theme) => ({
  [theme.mediaQuery.small]: {
    width: "100%",
  },
  [theme.mediaQuery.large]: {
    width: "auto",
  },
});
const INPUT_OVERRIDE_STYLE = {
  Input: ({ $theme }) => ({
    backgroundColor: "none",
    color: $theme.RAColors?.gray900,
  }),
  Root: ({ $theme }) => ({
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    width: "100%",
  }),
  StartEnhancer: ({ $theme }) => ({
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: "transparent",
  }),
};
const TABLE_OVERRIDE_STYLE = {
  Root: ({ $theme }) => ({
    ...COMMON_RADIUS("8px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    ...COMMON_BORDER_STYLE("solid"),
  }),
  NameCellHead: ({ $theme }) => ({
    width: "250px",
    maxWidth: "250px",
    [$theme.mediaQuery.small]: {
      display: "none",
    },
    [$theme.mediaQuery.large]: {
      display: "table-cell",
      width: "250px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
  }),
  NameCell: ({ $theme }) => ({
    width: "250px",
    maxWidth: "250px",
    [$theme.mediaQuery.small]: {
      display: "grid",
      width: "auto",
      border: "none",
      textAlign: "center",
      maxWidth: "100%",
    },
    [$theme.mediaQuery.large]: {
      display: "table-cell",
      width: "250px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      maxWidth: "250px",
      textAlign: "left",
    },
  }),
  TrackCellHead: ({ $theme }) => ({
    width: "500px",
    maxWidth: "500px",
    [$theme.mediaQuery.small]: {
      display: "none",
    },
    [$theme.mediaQuery.large]: {
      display: "table-cell",
      width: "500px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
  }),
  TrackCell: ({ $theme }) => ({
    width: "500px",
    maxWidth: "500px",
    [$theme.mediaQuery.small]: {
      display: "grid",
      width: "auto",
      border: "none",
      textAlign: "center",
    },
    [$theme.mediaQuery.large]: {
      display: "table-cell",
      width: "500px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      textAlign: "left",
    },
  }),
  TimeCellHead: ({ $theme }) => ({
    width: "200px",
    maxWidth: "200px",
    [$theme.mediaQuery.small]: {
      display: "none",
    },
    [$theme.mediaQuery.large]: {
      display: "table-cell",
      width: "200px",
      maxWidth: "200px",
    },
  }),
  TimeCell: ({ $theme }) => ({
    width: "200px",
    maxWidth: "200px",
    [$theme.mediaQuery.small]: {
      display: "grid",
      width: "auto",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      maxWidth: "100%",
      textAlign: "center",
    },
    [$theme.mediaQuery.large]: {
      display: "table-cell",
      width: "200px",
      maxWidth: "200px",
      textAlign: "left",
    },
  }),
};
const TABLE_CUSTOM_CELL_STYLE_TASK = (theme) => ({
  ...TABLE_OVERRIDE_STYLE.TrackCell,
  fontSize: "14px",
  lineHeight: "20px",
  padding: "16px",
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  [theme.mediaQuery.small]: {
    display: "grid",
    borderBottom: "none",
  },
  [theme.mediaQuery.large]: {
    display: "table-cell",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
});

const TABLE_CUSTOM_CELL_STYLE = (theme) => ({
  ...TABLE_OVERRIDE_STYLE.TrackCell,
  fontSize: "14px",
  lineHeight: "20px",
  padding: "16px",
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  [theme.mediaQuery.small]: {
    display: "grid",
  },
  [theme.mediaQuery.large]: {
    display: "table-cell",
  },
});

// const resp = JSON.parse(localStorage.getItem("assignment"));
const ToggleIcon = ({ $expanded, $theme }) => {
  const [css] = useStyletron();
  const color = $theme?.RAColors?.gray900;

  if ($expanded)
    return (
      <ChevronUp
        data-html2canvas-ignore
        className={css({ minWidth: "20px" })}
        size={20}
        color={color}
      />
    );
  return (
    <ChevronDown
      data-html2canvas-ignore
      size={20}
      className={css({ minWidth: "20px" })}
      color={color}
    />
  );
};
const ACCORDION_HEADER_STYLE = {
  Header: ({ $theme }) => ({
    paddingBottom: "0px",
    paddingRight: "24px",
    paddingLeft: "24px",
    [$theme.mediaQuery.small]: {
      paddingRight: "18px",
      paddingLeft: "18px",
    },
    [$theme.mediaQuery.large]: {
      paddingRight: "24px",
      paddingLeft: "24px",
    },
  }),
};
const PANEL_CONTENT_STYLE = {
  Content: ({ $theme }) => ({
    paddingRight: "24px",
    paddingLeft: "24px",
    [$theme.mediaQuery.small]: {
      paddingRight: "18px",
      paddingLeft: "18px",
    },
    [$theme.mediaQuery.large]: {
      paddingRight: "24px",
      paddingLeft: "24px",
    },
  }),
};

export default function AssignmentsOverview(
  dataval = null,
  assgnName = null,
  questFilteredData = []
) {
  // const ModalComponent = lazy(() => import("./modal/index.js"));
  const resp = JSON.parse(localStorage.getItem("assignment"));
  const newData = (data) => {
    try {
      data?.forEach((items) => {
        // items.lesson_number = "Lesson " + (index + 1)
        // let completedAssignment = [];
        items?.assignment_list?.forEach((assignment) => {
          assignment.completed_on = dayjs(assignment?.last_update_date).format(
            "YYYY.MM.DD"
          );
          const sortable = Object.fromEntries(
            Object.entries(assignment?.skill_point_map).sort(
              ([, a], [, b]) => b - a
            )
          );
          let data1 = Object.keys(sortable).map((skillPoint) => {
            return assignment?.skill_point_map[skillPoint] + " " + skillPoint;
          });
          // completedAssignment.push(
          //   assignment?.assignment_progress?.progress_actual === 100 &&
          //     assignment?.assignment_room_code !== "NOP_ROOM_CODE_NOP_ROOM_CODE"
          // );
          assignment.skill_point = data1;
        });
        items.is_complete =
          items?.lesson_skill_points_actual / items.lesson_skill_points_total ==
          1
            ? true
            : false;
      });
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  let updatedLessonList = resp?.lesson_list.map((lesson, index) => ({
    ...lesson,
    lesson_number: "Stage " + (index + 1),
  }));

  // updatedLessonList= updatedLessonList?.map((lesson) => {
  //   let lxpAsgVal;
  //   dataval?.questFilteredData?.map((obj) => {
  //   if((obj.path.match( '-(.*)-' )[1]).substr(2,1) === lesson?.lesson_number.substr(6,1)) {
  //     //return ;
  //   let aa = lesson?.assignment_list?.sort((a, b) => a.assignment_type.length <  b.assignment_type.length ? -1 : 1)
  //   aa.map((asg, ind) => {
  //    if(asg.assignment_type === "" && obj.path.split("-").pop().substr(2,1) == ind+1){
  //           if(obj?.score === "0" && obj?.submitted_at === "0000-00-00 00:00:00"){
  //             lxpAsgVal = 0;
  //           }else{
  //             lxpAsgVal = 1;
  //           }
  //           asg.isAlxpAvailable = true;
  //           asg.assignment_progress.progress_actual_cnt = asg?.assignment_progress?.progress_actual_cnt + lxpAsgVal
  //           asg.assignment_progress.progress_total_cnt = asg?.assignment_progress?.progress_total_cnt + 1
  //         }
  //      //}

  //     })
  //   }
  // })

  // let aa = [
  //   {
  //       "lesson_name": "Introduction to Cybersecurity",
  //       "lesson_id": 21062,
  //       "room": [
  //           {
  //               "room_id": 21083,
  //               "room_name": "Cybersecurity History",
  //               "room_tags": [],
  //               "room_code": "",
  //               "room_thm": [],
  //               "room_type": "",
  //               "room_length": "",
  //               "room_description": "",
  //               "instructor_email_id": "",
  //               "learning_objective": {},
  //               "quiz": [],
  //               "room_categories": [],
  //               "task_complete": 0,
  //               "room_progress": "0\/1"
  //           },
  //           {
  //               "room_id": 21086,
  //               "room_name": "RA Orientation",
  //               "room_tags": [],
  //               "room_code": "",
  //               "room_thm": [],
  //               "room_type": "",
  //               "room_length": "",
  //               "room_description": "",
  //               "instructor_email_id": "",
  //               "learning_objective": {},
  //               "quiz": [],
  //               "room_categories": [],
  //               "task_complete": 0,
  //               "room_progress": "2\/3"
  //           }
  //       ]
  //     }
  //     ]

  //     dataval?.dataval?.map((obj) => {
  //     if(obj?.lesson_name === lesson?.lesson_name){
  //       lesson?.assignment_list.map((el1) => {
  //           obj?.room.map((ele) => {
  //             if(ele?.room_name === el1?.assignment_name){
  //               let vals_actual = parseInt(ele?.room_progress.split("\/")[0])
  //               let vals_total = parseInt(ele?.room_progress.split("\/").pop())
  //               el1.assignment_progress.progress_actual_cnt = el1?.assignment_progress?.progress_actual_cnt + vals_actual
  //               el1.assignment_progress.progress_total_cnt = el1?.assignment_progress?.progress_total_cnt + vals_total
  //             }
  //           })
  //       })
  //   }
  // })

  // return lesson
  // })

  updatedLessonList = newData(updatedLessonList);
  const [css, theme] = useStyletron();
  const state = useTrackedState();
  const [searchString, setSearchString] = useState("");
  const [lessonList, setLessonList] = useState([]);

  useEffect(() => {
    // newData()
    setLessonList(updatedLessonList);

    // getPathAndScore(resp.course_name, localStorage.getItem("canvas"));
  }, [dataval]);

  const TrackProgress = ({ row }) => {
    const progressData =
      (row?.lesson_skill_points_actual * 100) / row?.lesson_skill_points_total;
    const [css, theme] = useStyletron();
    const COMMON_PROGRESS_STYLE = {
      height: "6px",
      width: "102px",
      borderRadius: "100px",
    };
    return (
      <Block
        display="flex"
        flexDirection="row"
        marginTop="15px"
        alignItems="center"
      >
        <Block
          display="flex"
          position="relative"
          marginLeft="10px"
          className={css({
            ...COMMON_PROGRESS_STYLE,
            backgroundColor: theme.RAColors?.gray200,
          })}
        >
          <Block
            position="absolute"
            className={css({
              ...COMMON_PROGRESS_STYLE,
              backgroundColor: theme.RAColors?.blue600,
              width: `${progressData}%`,
            })}
          />
        </Block>
      </Block>
    );
  };
  const AnsweredTrackProgress = ({ row }) => {
    let Answered_Actual = 0;
    let Answered_Total = 0;
    row?.length > 0 &&
      row?.map((assignData) => {
        if (assignData?.assignment_type != "Exercise__Independent_Optional") {
          Answered_Actual =
            Answered_Actual +
            assignData?.assignment_progress?.progress_actual_cnt;
          Answered_Total =
            Answered_Total +
            assignData?.assignment_progress?.progress_total_cnt;
        }
      });
    const progressData = (Answered_Actual * 100) / Answered_Total;
    const [css, theme] = useStyletron();
    const COMMON_PROGRESS_STYLE = {
      height: "6px",
      width: "102px",
      borderRadius: "100px",
    };
    return (
      <Block
        display="flex"
        flexDirection="row"
        marginTop="15px"
        alignItems="center"
      >
        <Block
          display="flex"
          position="relative"
          marginLeft="10px"
          className={css({
            ...COMMON_PROGRESS_STYLE,
            backgroundColor: theme.RAColors?.gray200,
          })}
        >
          <Block
            position="absolute"
            className={css({
              ...COMMON_PROGRESS_STYLE,
              backgroundColor: theme.RAColors?.blue600,
              width: `${progressData}%`,
            })}
          />
        </Block>
      </Block>
    );
  };
  const searchAssignments = (event) => {
    setSearchString(event.target.value);
    if (event.target.value !== "") {
      search(event, updatedLessonList, (searchedList) =>
        searchedResult(searchedList)
      );
    } else {
      setLessonList(updatedLessonList);
    }
  };
  const searchedResult = (list) => {
    setLessonList(newData(list));
  };
  const calculateTotalAnswered = (assignments) => {
    let Total_Answered = 0;
    assignments?.length > 0 &&
      assignments?.map((assignData) => {
        Total_Answered =
          Total_Answered + assignData?.assignment_progress?.progress_actual_cnt;
      });
    return Total_Answered;
  };

  const getAssignmentList = (lessonData, taskType = "CORE") => {
    let taskArray = [];
    if (taskType === "CORE") {
      taskArray = lessonData?.assignment_list?.filter((assignData) => {
        return assignData.assignment_type !== "Exercise__Independent_Optional";
      });
    } else {
      taskArray = lessonData?.assignment_list?.filter((assignData) => {
        return assignData.assignment_type === "Exercise__Independent_Optional";
      });
    }

    return taskArray;
  };

  function checkcoreroomForFlex(data) {
    if (data?.lesson_name?.includes("Flex")) {
      return true;
    } else {
      return false;
    }
  }

  const totalFlexSkillPointsEarned = (skill_points) => {
    let Total_Answered = 0;
    skill_points?.length > 0 &&
      skill_points?.map((flexData) => {
        Object.values(flexData.skill_point_map).map((keys) => {
          Total_Answered = Total_Answered + keys;
        });
      });
    return Total_Answered;
  };
  const RenderAccordian = () => {
    return lessonList?.length > 0 ? (
      lessonList?.map((lessonData, index) => {
        return (
          <Block
            className={css(ACCORDION_BLOCK_STYLE(theme))}
            key={index}
            data-testid="assignment-table-row"
          >
            <Accordion
              accordion={false}
              initialState={{
                expanded: (lessonList || []).map(
                  (lesson, phaseIndexAccordion) =>
                    lesson.is_complete === false
                      ? String(phaseIndexAccordion)
                      : null
                ),
              }}
            >
              <Panel
                overrides={{
                  ToggleIcon,
                  Content: {
                    style: PANEL_CONTENT_STYLE.Content,
                  },
                  Header: {
                    style: ACCORDION_HEADER_STYLE.Header,
                  },
                  PanelContainer: { style: { borderBottomWidth: "0px" } },
                }}
                key={index}
                title={
                  <Block display="block" className={css(LESSON_SECTION(theme))}>
                    <Block
                      className={css(LESSON_STYLE(theme))}
                      data-testid="lesson-number"
                    >
                      {lessonData?.lesson_number}
                    </Block>
                    <Block
                      as="h3"
                      className={css(HEADING_STYLE(theme))}
                      marginBottom={
                        checkcoreroomForFlex(lessonData) ? "0px" : "15px"
                      }
                      data-testid="lesson-name"
                    >
                      {lessonData?.lesson_name}
                    </Block>
                    {checkcoreroomForFlex(lessonData) ? null : (
                      <Block>
                        <Block
                          as="h3"
                          className={css(HEADING_STYLE(theme))}
                          data-testid="core_task_text"
                        >
                          {"Core Rooms"}
                        </Block>
                        <Block className={css(SKILL_SECTION(theme))}>
                          <Block>
                            <Block
                              className={css(SUBHEADING_STYLE(theme))}
                              data-testid="core_skill_points"
                            >
                              {"Core skill points earned"}
                            </Block>
                            <Block display="flex" alignItems="center">
                              <Block
                                className={css(VALUES_STYLE(theme))}
                                data-testid="average_skill_point"
                              >
                                {lessonData.lesson_skill_points_actual +
                                  " / " +
                                  lessonData.lesson_skill_points_total}
                              </Block>
                              <Block marginBottom="15px">
                                <TrackProgress row={lessonData} />
                              </Block>
                            </Block>
                          </Block>
                          <Block className={css(QUES_SECTION(theme))}>
                            <Block
                              className={css(SUBHEADING_STYLE(theme))}
                              data-testid="core_question_answer"
                            >
                              {"Core questions answered"}
                            </Block>
                            <Block display="flex" alignItems="center">
                              <Block className={css(VALUES_STYLE(theme))}>
                                {calculateTotalAnswered(
                                  getAssignmentList(lessonData)
                                )}
                                /
                                {
                                  lessonData?.progress_lesson
                                    ?.progress_actual_cnt
                                }
                              </Block>
                              <Block marginBottom="15px">
                                <AnsweredTrackProgress
                                  row={lessonData.assignment_list}
                                />
                              </Block>
                            </Block>
                          </Block>
                        </Block>
                      </Block>
                    )}
                  </Block>
                }
              >
                <Block>
                  {checkcoreroomForFlex(lessonData) ? null : getAssignmentList(
                      lessonData
                    ) === null ? null : (
                    <TableBuilder
                      data={getAssignmentList(lessonData)}
                      overrides={{
                        Root: { style: TABLE_OVERRIDE_STYLE.Root },
                        TableBodyRow: {
                          component: ({ children }) => <tr>{children}</tr>,
                        },
                      }}
                      isLoading={state.isFetching || false}
                      emptyMessage="No data"
                    >
                      <TableBuilderColumn
                        header={
                          <TableHeader name={"Rooms"} enableSort={false} />
                        }
                        overrides={{
                          TableHeadCell: {
                            style: TABLE_OVERRIDE_STYLE.NameCellHead,
                          },
                          TableBodyCell: {
                            style: TABLE_OVERRIDE_STYLE.NameCell,
                          },
                        }}
                      >
                        {(row) => (
                          <TableTask
                            className={css(TABLE_CUSTOM_CELL_STYLE(theme))}
                            row={row}
                          />
                        )}
                      </TableBuilderColumn>
                      <TableBuilderColumn
                        header={
                          <TableHeader
                            name={"Skill points earned"}
                            enableSort={false}
                          />
                        }
                        overrides={{
                          TableHeadCell: {
                            style: TABLE_OVERRIDE_STYLE.TrackCellHead,
                          },
                          TableBodyCell: {
                            component: ({ $row, children, ...rest }) => (
                              <td
                                className={css(
                                  TABLE_CUSTOM_CELL_STYLE_TASK(theme)
                                )}
                              >
                                {children}
                              </td>
                            ),
                          },
                        }}
                      >
                        {(row) => (
                          <TableSkillsPoints
                            className={css(TABLE_CUSTOM_CELL_STYLE_TASK(theme))}
                            row={row}
                          />
                        )}
                      </TableBuilderColumn>
                      <TableBuilderColumn
                        header={
                          <TableHeader name={"Completion"} enableSort={false} />
                        }
                        overrides={{
                          TableHeadCell: {
                            style: TABLE_OVERRIDE_STYLE.TimeCellHead,
                          },
                          TableBodyCell: {
                            style: TABLE_OVERRIDE_STYLE.TimeCell,
                          },
                        }}
                      >
                        {(row) => (
                          <TableCompletionDate
                            className={css(TABLE_CUSTOM_CELL_STYLE(theme))}
                            row={row}
                            data={dataval}
                          />
                        )}
                      </TableBuilderColumn>
                    </TableBuilder>
                  )}{" "}
                </Block>
                <Block
                  display="block"
                  className={css(LESSON_SECTION(theme))}
                  paddingTop={checkcoreroomForFlex(lessonData) ? "0px" : "15px"}
                  paddingBottom="24px"
                >
                  <Block
                    as="h3"
                    className={css(HEADING_STYLE(theme))}
                    data-testid="additional_task_text"
                  >
                    {"Additional Practice Rooms"}
                  </Block>
                  <Block className={css(SKILL_SECTION(theme))}>
                    <Block>
                      <Block
                        className={css(SUBHEADING_STYLE(theme))}
                        data-testid="additional_practice_point"
                      >
                        {"Additional Practice skill points earned"}
                      </Block>
                      <Block display="flex" alignItems="center" marginTop="5px">
                        <Block
                          className={css(VALUES_STYLE(theme))}
                          data-testid="flex-skill-points"
                        >
                          {lessonData.lesson_flex_skill_points_actual}
                        </Block>
                      </Block>
                    </Block>
                    <Block className={css(QUES_SECTION(theme))}>
                      <Block
                        className={css(SUBHEADING_STYLE(theme))}
                        data-testid="additional_skill_question"
                      >
                        {"Additional Practice questions answered"}
                      </Block>
                      <Block display="flex" alignItems="center" marginTop="5px">
                        <Block className={css(VALUES_STYLE(theme))}>
                          {calculateTotalAnswered(
                            getAssignmentList(lessonData, "OPTIONAL")
                          )}
                        </Block>
                      </Block>
                    </Block>
                  </Block>
                </Block>
                <TableBuilder
                  data={getAssignmentList(lessonData, "OPTIONAL")}
                  overrides={{
                    Root: { style: TABLE_OVERRIDE_STYLE.Root },
                    TableBodyRow: {
                      component: ({ children }) => <tr>{children}</tr>,
                    },
                  }}
                  isLoading={state.isFetching || false}
                  emptyMessage="No data"
                >
                  <TableBuilderColumn
                    header={<TableHeader name={"Rooms"} enableSort={false} />}
                    overrides={{
                      TableHeadCell: {
                        style: TABLE_OVERRIDE_STYLE.NameCellHead,
                      },
                      TableBodyCell: { style: TABLE_OVERRIDE_STYLE.NameCell },
                    }}
                  >
                    {(row) => (
                      <TableTask
                        className={css(TABLE_CUSTOM_CELL_STYLE(theme))}
                        row={row}
                      />
                    )}
                  </TableBuilderColumn>
                  <TableBuilderColumn
                    header={
                      <TableHeader
                        name={"Skill points earned"}
                        enableSort={false}
                      />
                    }
                    overrides={{
                      TableHeadCell: {
                        style: TABLE_OVERRIDE_STYLE.TrackCellHead,
                      },
                      TableBodyCell: {
                        component: ({ $row, children, ...rest }) => (
                          <td
                            className={css(TABLE_CUSTOM_CELL_STYLE_TASK(theme))}
                          >
                            {children}
                          </td>
                        ),
                      },
                    }}
                  >
                    {(row) => (
                      <TableSkillsPoints
                        className={css(TABLE_CUSTOM_CELL_STYLE_TASK(theme))}
                        row={row}
                      />
                    )}
                  </TableBuilderColumn>
                  <TableBuilderColumn
                    header={
                      <TableHeader name={"Completion"} enableSort={false} />
                    }
                    overrides={{
                      TableHeadCell: {
                        style: TABLE_OVERRIDE_STYLE.TimeCellHead,
                      },
                      TableBodyCell: { style: TABLE_OVERRIDE_STYLE.TimeCell },
                    }}
                  >
                    {(row) => (
                      <TableCompletionDate
                        className={css(TABLE_CUSTOM_CELL_STYLE(theme))}
                        row={row}
                      />
                    )}
                  </TableBuilderColumn>
                </TableBuilder>
              </Panel>
            </Accordion>
          </Block>
        );
      })
    ) : (
      <Block data-testid="assignment-no-data">No data</Block>
    );
  };
  return (
    <Block className={css(COMMON_CARD_STYLE(theme))}>
      <Block
        display="flex"
        flexWrap="wrap"
        gridGap="24px"
        className={css(FILTER_STYLE(theme))}
        data-html2canvas-ignore
      >
        <Block
          data-testid="filter-items-title"
          className={css(FILTER_SEARCH(theme))}
        >
          <Input
            value={searchString}
            onChange={searchAssignments}
            name="search"
            placeholder={Search_Text}
            clearOnEscape
            data-testid="search_input"
            overrides={{
              Input: { style: INPUT_OVERRIDE_STYLE.Input },
              Root: { style: INPUT_OVERRIDE_STYLE.Root },
              StartEnhancer: { style: INPUT_OVERRIDE_STYLE.StartEnhancer },
            }}
            startEnhancer={
              <SearchIcon size={24} color={theme.RAColors?.gray900} />
            }
          />
        </Block>
      </Block>
      <RenderAccordian />
    </Block>
  );
}
