
import axios from "axios";
import { kahootAuthURL, score, wp_score } from "../config";
import { KAHOOT_AUTH, KAHOOT_GAME_SCORE } from "../apiEndpoint";
import { studentAxios } from "services/http/http";

const KahootRoomScoreBoardAxios = axios.create({
  baseURL: kahootAuthURL,
});

KahootRoomScoreBoardAxios.defaults.headers.common = {
  ...(KahootRoomScoreBoardAxios.defaults.headers.common || {}),
  "Content-Type": "application/x-www-form-urlencoded",
};
studentAxios.defaults.headers.common = {
  ...(studentAxios.defaults.headers.common || {}),
  ApiKey: "TESTDEV-0000-1111-2222-333333333333",
};

const clientId = process.env.REACT_APP_KAHOOT_CLIENT_ID;
const client_secret = process.env.REACT_APP_KAHOOT_CLIENT_SECRET;
const grant_type = process.env.REACT_APP_KAHOOT_GRANT_TYPE;

// export function fetchKahootScore(payload) {
//   return studentAxios.post(KAHOOT_GAME_SCORE, payload);

// }

export async function fetchKahootScore() {
  try {
    const response = await axios.get(
      `${score}?user_email=${localStorage.getItem(
        "email"
      )}&&role=${localStorage.getItem("role")}`
    );
    return response; // Assuming the API returns a URL for the image
  } catch (error) {
    console.error("Error fetching new data:", error);
  }
}
const JumpStartData = axios.create({
  baseURL: wp_score,
});

JumpStartData.defaults.headers.common = {
  ...(JumpStartData.defaults.headers.common || {}),
  "x-api-key": "8jSs34s*2828",
};
export async function fetchWpScore() {
  try {
    const response = JumpStartData.get(
      `cohort_student_data?email_id=${localStorage.getItem(
        "email"
      )}&&role=${localStorage.getItem("role")}`
    );
    return response; // Assuming the API returns a URL for the image
  } catch (error) {
    console.error("Error fetching new data:", error);
  }
}
export default function fetchKahootRoomScoreBoard() {
    return KahootRoomScoreBoardAxios.post(`${KAHOOT_AUTH}?client_id=${clientId}&client_secret=${client_secret}&grant_type=${grant_type}`);
}


// export default function fetchKahootRoomScoreBoard() {
//   KahootRoomScoreBoardAxios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// return KahootRoomScoreBoardAxios.post(KAHOOT_AUTH+`?client_id=${clientId}&client_secret=${client_secret}&grant_type=${grant_type}`);
// }
