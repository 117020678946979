import React, { useEffect } from "react";
import Loader from "react-js-loader";
import { Notification, KIND } from "baseui/notification";
import DeleteAlt from "baseui/icon/delete-alt";
import "./styles.css";
import KeyCloakService from "../../services/http/KeycloakService";
import StudentAPI from "services/requests/student_api";
import getProfile from "../../services/requests/image";
import { useSetState, useTrackedState } from "store/store";
import { useHistory } from "react-router-dom";
import RolesPermission from "services/requests/rolesPermissions";
import FetchCohortData from "services/requests/dol_JS";
import getMenu from "../../components/menu"
import loggedUser from "services/requests/loggedUser";
import userProfile from "services/requests/user_api";
import { replaceValuesInJson } from "services/requests/learnerList";
import { mapStudentData } from "utils/app-utils";

export default function KeyCloakSSO() {
  const history = useHistory();

  const [loader, setLoader] = React.useState(true);
  const [errmsg, setErrMsg] = React.useState("");
  const [keycloak, setKeyCloak] = React.useState(null);
  const setState = useSetState();
  const state = useTrackedState();

  const role_weight = [
    { label: "linkforce_admin", value: 1 },
    { label: "tenant_owner", value: 2 },
    { label: "rapidascentautomation_manager", value: 3 },
    { label: "linkforce_manager", value: 4 },
    { label: "linkforce_mentor", value: 5 },
    { label: "linkforce_learner", value: 6 },
  ];

  const successCallback = (authenticated) => {
    Login(authenticated);
  };
  const failureCallback = (error) => {
    console.log("keycloak auth error", error);
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (
      localStorage.getItem("viewAs") === null &&
      queryParams.get("role") === null &&
      localStorage.getItem("superAdmin") === null
    ) {
      KeyCloakService.initKeycloakForSSO(successCallback, failureCallback);
    } else if (queryParams.get("role") !== null) {
      let role = queryParams.get("role").toUpperCase();
      localStorage.setItem("role", role);
      let username = queryParams.get("username");
      let email = queryParams.get("email");
      let firstName = username.split(" ").slice(0, -1).join(" ");
      let lastName = username.split(" ").slice(-1).join(" ");
      localStorage.setItem("username", username);
      localStorage.setItem("firstName", firstName);
      localStorage.setItem("lastName", lastName);
      localStorage.setItem("email", email);
      LoginSSO(email, username);
    } else {
      loadSectionViewAs();
    }
  }, []);

  //redirected from wordpress
  async function LoginSSO(email, username) {
    await rolesPermissions(email, localStorage.getItem("role"));
    await getImage(email);
    await loggedDetail(email, username);
  }

  async function Login(authenticated) {
    // setKeyCloak(true)
    if (authenticated) {
      try {
        // Compare the hashed jwt with the one received
        const successCallback = async (profile) => {
          //console.log("profile",profile)
          let username = profile.firstName + " " + profile.lastName;
          let email = profile.email;
          // userRole(user);
          localStorage.setItem("username", username);
          localStorage.setItem("firstName", profile.firstName);
          localStorage.setItem("lastName", profile.lastName);
          localStorage.setItem("email", email);
          if (localStorage.getItem("role") === "ADMIN") {
            localStorage.setItem("resetViewAs", "ADMIN");
            localStorage.setItem("resetEmail", email);
            localStorage.setItem("resetLastName", profile.lastName);
            localStorage.setItem("resetUsername", username);
            localStorage.setItem("resetFirstName", profile.firstName);
            localStorage.setItem("resetRole", localStorage.getItem("role"));
          }
          await rolesPermissions(email, localStorage.getItem("role"));
          //await fetchWordpressData(email)
          await getImage(email);
          await loggedDetail(email, username);
          //loadSection();
        };
        const failureCallback = (error) => {
          console.log("keycloak auth error", error);
          setLoader(false);
        };
        KeyCloakService.loadProfileDetail(successCallback, failureCallback);
        const parsedToken = KeyCloakService.getParsedToken();
        //console.log("parsed Token", parsedToken);
        userRole(parsedToken.realm_access);
        setErrMsg("");
        // You can create a user session here if required and navigate to the home page;
      } catch (error) {
        setErrMsg("Oops! Something went wrong. Please try again!");
        console.error(error);
      }
    } else {
      setLoader(false);
      history.push("/login");
    }
  }

  const rolesPermissions = (userEmail, role) => {
    RolesPermission(userEmail, role)
      .then(function (response) {
        if (response.status === 200) {
          localStorage.setItem(
            "rolesPermission",
            JSON.stringify(response.data)
          );
          getMenu();
          setTimeout(() => {
            loadSection();
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loggedDetail = (userEmail, name) => {
    loggedUser(userEmail, name, "POST")
      .then(function (response) {
        if (response.status === 200) {
          //console.log("success")
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchWordpressData = (userEmail) => {
    FetchCohortData(userEmail)
      .then(function (response) {
        if (response.status === 200) {
          localStorage.setItem("wordpress", JSON.stringify(response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadSectionViewAs = () => {
    let url = localStorage.getItem("currentPage");
    if (
      localStorage.getItem("viewAs") === "ADMIN" ||
      localStorage.getItem("viewAs") === "TENANT OWNER" ||
      localStorage.getItem("viewAs") === "MANAGER"
    ) {
      history.push(url);
    } else if (localStorage.getItem("viewAs") === "MENTOR") {
      history.push(url);
    } else if (localStorage.getItem("viewAs") === "LEARNER") {
      studentTrack();
    } else if (localStorage.getItem("viewAs") !== null) {
      history.push("/landing");
    }
  };

  function getImage(email) {
    return getProfile(email)
      .then((response) => {
        if (response.status === 200) {
          if (response.data !== "Image Not Found!") {
            console.log("Image", response.data?.image);
            localStorage.setItem("image", response.data?.image);
          } else {
            localStorage.setItem("image", null);
          }
        } else {
          localStorage.setItem("image", null);
        }
      })
      .catch((err) => {
        setLoader(false);
        localStorage.setItem("image", null);
      });
  }

  const studentTrack = async () => {
    try {
      var email_val;
      if (localStorage.getItem("view_as_learner") === null) {
        email_val = localStorage.getItem("email");
      } else if (localStorage.getItem("superAdmin") === "RA-ADMIN") {
        email_val = localStorage.getItem("email");
      } else {
        email_val = localStorage.getItem("view_as_learner");
      }
      const response = await StudentAPI(
        email_val,
        localStorage.getItem("role"),
        email_val
      );
      if (response.status === 200) {
        const studentOverview = response.data[0]?.learner_profile_list[0];
        localStorage.setItem(
          "learner_data",
          response.data[0].learner_profile_list.length
        );
        if (response.data[0]?.learner_profile_list?.length > 0) {
          //<--------------code replacing with student node--------------->
          const replaceResponse = await replaceValuesInJson();
          const filterProfile =
            replaceResponse?.data[0]?.learner_profile_list?.filter(
              (item) =>
                item.email_id ===
                  response?.data[0]?.request_parameters?.user_email &&
                item?.learning_path?.learning_path_name ===
                  studentOverview?.learning_path_name
            );
          const studentOverviewReplace = mapStudentData(
            response.data[0]?.learner_profile_list,
            // replaceResponse?.data[0]?.learner_profile_list
            filterProfile
          );
          localStorage.setItem("track", studentOverviewReplace[0]);
          // <---------end--------------->
          // localStorage.setItem("track", studentOverview);
          localStorage.setItem(
            "thm_username",
            studentOverview.tryhackme_username
          );
          setState(
            (prev) => ({
              ...prev,
              track: studentOverviewReplace[0],
              // track: studentOverview,
              isFetchTrackData: true,
            }),
            () => {
              let url = localStorage.getItem("currentPage");
              if (url !== "competency-sign-off" && url !== "personal-info") {
                console.log(studentOverview);
                validateThmUser();
              } else {
                history.push(url);
              }
            }
          );
        } else {
          history.push("/landing");
        }
      } else {
        //console.log("somethinh went wrong")
        history.push("/landing");
      }
    } catch (e) {
      console.log(e);
      setState((prev) => ({
        ...prev,
        track: {},
        isFetchTrackData: false,
      }));
    }
  };

  function userRole(user) {
    // console.log("user ROle",user)
    const myArrayFiltered = role_weight?.filter((el) => {
      return user.roles?.some((f) => {
        return f === el.label;
      });
    });

    var userVal = myArrayFiltered[0]?.value;
    switch (userVal) {
      case 1:
        localStorage.setItem("role", "ADMIN");
        break;
      case 2:
        localStorage.setItem("role", "TENANT OWNER");
        break;
      case 3:
        localStorage.setItem("role", "MANAGER");
        break;
      case 4:
        localStorage.setItem("role", "MANAGER");
        break;
      case 5:
        localStorage.setItem("role", "MENTOR");
        break;
      case 6:
        localStorage.setItem("role", "LEARNER");
        break;
      default:
        localStorage.setItem("role", "LEARNER");
        break;
    }
    //user.groups.includes("tenant_owner") ? localStorage.setItem("role", "TENANT OWNER") : user.groups.includes("linkforce_manager") ? localStorage.setItem("role", "ADMIN") : (user.groups.includes("linkforce_mentor") ? localStorage.setItem("role", "MENTOR") : (user.groups.includes("rapidascentautomation_manager") ? localStorage.setItem("role", "MANAGER") : (user.groups.includes("linkforce_learner") ? localStorage.setItem("role", "LEARNER") : localStorage.setItem("role", "LEARNER"))));
  }

  const loadSection = () => {
    if (
      localStorage.getItem("role") === "ADMIN" ||
      localStorage.getItem("role") === "TENANT OWNER" ||
      localStorage.getItem("role") === "MANAGER"
    ) {
      history.push("/student");
    } else if (localStorage.getItem("role") === "MENTOR") {
      history.push("/student");
    } else if (localStorage.getItem("role") === "LEARNER") {
      studentTrack();
    } else {
      history.push("/landing");
    }
  };

  const validateThmUser = () => {
    userProfile(localStorage.getItem("email")).then(function (response) {
      if (response.status === 200) {
        if (!response.data[0].thmusername) {
          history.push("/personal-info");
        } else {
          history.push("/tracksummary");
        }
      }
    });
  };
  return (
    <div className="overview_loginSection">
      {errmsg ? (
        <React.Fragment>
          <Notification
            overrides={{
              Body: {
                style: ({ $theme }) => ({
                  position: "absolute",
                  top: "5%;",
                  right: "0",
                  background: "red",
                  color: "#fff",
                }),
              },
              CloseIcon: {
                component: DeleteAlt,
                style: { float: "right", cursor: "pointer" },
              },
            }}
            kind={KIND.negative}
            closeable
          >
            {errmsg}
          </Notification>
        </React.Fragment>
      ) : null}
      {loader ? (
        <div className="loader-bg">
          <Loader
            type="bubble-loop"
            bgColor={"#FFFFFF"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      ) : null}
      <div className="overview_ssohome">
        <div className="brandLogo"></div>
      </div>
    </div>
  );
}
